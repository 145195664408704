import React from "react";
import { BlogNodeType } from "@graphQL/blogMetaContent";
import Container from "@components/Container";
import { renderRichText } from "@components/Contentful";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Layout from "@components/Layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import ButtonLink from "@components/ButtonLink";
import Moment from "react-moment";
import { Media } from "@ryerson/frontend.layout";
import { Twitter, Facebook, Linkedin, CopyLink } from "@components/Icons";
import Meta, { MetaContent } from "@components/MetaHelmet";

const Divider = styled.div`
	display: block;
	width: 100%;
	height: 1px;
	box-sizing: border-box;
	opacity: 0.2;
`;

const ShareButton = styled.div`
	width: 30px;
	height: 30px;
	display: inline-block;
	margin-left: 10px;
	border-radius: 30px;
	box-sizing: border-box;
	vertical-align: top;
	position: relative;
	cursor: pointer;
`;

const SocialIcon = styled.div`
	position: absolute;
	width: 16px;
	color: black;
	height: 16px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	line-height: 20px;
`;

export default (props: any) => {
	const { theme } = useTheme();
	if (
		props &&
		props.pageResources &&
		props.pageResources.json &&
		props.pageResources.json.pageContext &&
		props.pageResources.json.pageContext.contentfulData
	) {
		const data: BlogNodeType = props.pageResources.json.pageContext.contentfulData;
		const SEO: MetaContent = {
			title: data.title + " - The Next Shift",
			description: data.seoDescription,
			url: "/insights/" + data.url,
		};
		return (
			<Layout>
				<Meta content={SEO} />
				<Container>
					<Divider
						css={css`
							border-top: 1px solid ${theme.colors.tertiary.background};
							margin-bottom: 80px;
						`}
					/>
					<Typography
						variant="div"
						color={theme.colors.primary.primaryBrand}
						weight="bolder"
						css={css`
							font-size: 20px;
							text-align: center;
							line-height: 24px;
							margin-bottom: 20px;
						`}
					>
						BLOG
					</Typography>
					<Typography
						variant="h1"
						css={css`
							font-size: 48px;
							line-height: 58px;
							text-align: center;
							margin-bottom: 20px;
							@media (max-width: ${theme.breakpoints.lg}) {
								font-size: 36px;
								line-height: 42px;
							}
						`}
					>
						{data.title}
					</Typography>
					<Typography
						variant="div"
						size="md"
						weight="bolder"
						color={theme.colors.primary.secondaryBrand}
						css={css`
							margin-bottom: 10px;
							text-align: center;
						`}
					>
						<Moment format="D MMMM YYYY">{data.date}</Moment>
					</Typography>
					<div
						css={css`
							text-align: center;
						`}
					>
						<Typography
							variant="div"
							size="md"
							color={theme.colors.primary.secondaryBrand}
							css={css`
								display: inline-block;
								width: auto;
								white-space: nowrap;
								padding: 5px 10px;
								border: 1px solid ${theme.colors.primary.secondaryBrand};
								border-radius: 30px;
							`}
						>
							{data.category}
						</Typography>
					</div>
					<Media greaterThanOrEqual="lg">
						<img
							src={data.heroImage.file.url}
							alt={data.title}
							css={css`
								width: 100%;
								height: auto;
								margin-top: 60px;
								display: block;
								margin-bottom: 60px;
							`}
						/>
					</Media>
				</Container>
				<Media lessThan="lg">
					<img
						src={data.heroImage.file.url}
						alt={data.title}
						css={css`
							width: 100%;
							height: auto;
							margin-top: 50px;
							display: block;
							margin-bottom: 40px;
						`}
					/>
				</Media>
				<Container
					css={css`
						hr {
							opacity: 0.2;
							margin-top: 40px;
							margin-bottom: 40px;
							display: block;
							@media (max-width: ${theme.breakpoints.lg}) {
								margin-top: 20px;
								margin-bottom: 20px;
							}
						}
					`}
				>
					<div
						css={css`
							display: block;
							width: 100%;
							max-width: 790px;
							margin: 0 auto;
						`}
					>
						{renderRichText(data.body.raw, data.body.references)}
						<ButtonLink to={data.ctaLink} external={true}>
							{data.ctaText}
						</ButtonLink>
						<Divider
							css={css`
								border-top: 1px solid ${theme.colors.tertiary.background};
								margin-top: 60px;
								margin-bottom: 20px;
								@media (max-width: ${theme.breakpoints.lg}) {
									margin-top: 40px;
								}
							`}
						/>
					</div>
					<div
						css={css`
							display: block;
							width: 100%;
							text-align: center;
						`}
					>
						<Typography
							variant="div"
							color={theme.colors.primary.secondaryBrand}
							css={css`
								font-size: 16px;
								display: inline-block;
								line-height: 30px;
								vertical-align: top;
								@media (max-width: ${theme.breakpoints.lg}) {
									line-height: 30px;
									font-size: 16px;
								}
							`}
						>
							Share:
						</Typography>
						<ShareButton
							css={css`
								border: 1px solid ${theme.colors.primary.secondaryBrand};
								&:hover {
									border: 1px solid ${theme.colors.primary.accentBrand};
									background-color: ${theme.colors.primary.accentBrand};
								}
							`}
							onClick={() => {
								navigator.clipboard.writeText(
									window.location.origin + "/insights/" + data.url
								);
							}}
						>
							<SocialIcon>
								<CopyLink />
							</SocialIcon>
						</ShareButton>
						<a
							href={
								"https://twitter.com/intent/tweet?text=" +
								window.location.origin +
								"/insights/" +
								data.url
							}
							target="_blank"
						>
							<ShareButton
								css={css`
									border: 1px solid ${theme.colors.primary.secondaryBrand};
									&:hover {
										border: 1px solid ${theme.colors.primary.accentBrand};
										background-color: ${theme.colors.primary.accentBrand};
									}
								`}
							>
								<SocialIcon
									css={css`
										width: 18px;
										height: 18px;
									`}
								>
									<Twitter />
								</SocialIcon>
							</ShareButton>
						</a>
						<a
							href={
								"https://www.facebook.com/sharer/sharer.php?u=" +
								window.location.origin +
								"/insights/" +
								data.url
							}
							target="_blank"
						>
							<ShareButton
								css={css`
									border: 1px solid ${theme.colors.primary.secondaryBrand};
									&:hover {
										border: 1px solid ${theme.colors.primary.accentBrand};
										background-color: ${theme.colors.primary.accentBrand};
									}
								`}
							>
								<SocialIcon
									css={css`
										width: 20px;
										height: 20px;
									`}
								>
									<Facebook />
								</SocialIcon>
							</ShareButton>
						</a>
						<a
							href={
								"//www.linkedin.com/shareArticle?mini=true&url=" +
								window.location.origin +
								"/insights/" +
								data.url
							}
							target="_blank"
						>
							<ShareButton
								css={css`
									border: 1px solid ${theme.colors.primary.secondaryBrand};
									&:hover {
										border: 1px solid ${theme.colors.primary.accentBrand};
										background-color: ${theme.colors.primary.accentBrand};
									}
								`}
							>
								<SocialIcon>
									<Linkedin />
								</SocialIcon>
							</ShareButton>
						</a>
					</div>
					<Divider
						css={css`
							border-top: 1px solid ${theme.colors.tertiary.background};
							margin-top: 100px;
							@media (max-width: ${theme.breakpoints.lg}) {
								margin-top: 80px;
							}
						`}
					/>
				</Container>
			</Layout>
		);
	} else {
		return <></>;
	}
};
